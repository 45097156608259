/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function rtf2text(str) {
    var basicRtfPattern = /\{\*?\\[^{}]+;}|[{}]|\\[A-Za-z]+\n?(?:-?\d+)?[ ]?/g;
    var newLineSlashesPattern = /\\\n/g;
    var ctrlCharPattern = /\n\\f[0-9]\s/g;

    //Remove RTF Formatting, replace RTF new lines with real line breaks, and remove whitespace
    return str
        .replace(ctrlCharPattern, "")
        .replace(basicRtfPattern, "")
        .replace(newLineSlashesPattern, "\n")
        .trim();
}

export function html2text(str) {

    return str.replace(/<style([\s\S]*?)<\/style>/gi, '')
    .replace(/<script([\s\S]*?)<\/script>/gi, '')
    .replace(/(<(?:.|\n)*?>)/gm, '')
    .replace(/\s+/gm, ' ')
    .replace(/&quot;/g,'')
    .trim();

}
